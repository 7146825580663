export default [
  {
    prop: 'user_id',
    label: 'User ID',
    type: 'text',
    link: 'irace.vn/races/users',
    external: true,
    minWidth: 100
  },
  {
    prop: 'name',
    label: 'User',
    type: 'text',
    minWidth: 120
  },
  {
    prop: 'type',
    label: 'Loại',
    type: 'text',
    minWidth: 180
  },
  {
    prop: 'name',
    label: 'Title',
    type: 'text',
    minWidth: 200
  },
  {
    prop: 'date',
    label: 'Ngày',
    type: 'datetime',
    minWidth: 180
  },
  {
    prop: 'sets',
    label: 'Sets',
    type: 'text',
    minWidth: 80
  },
  {
    prop: 'reps',
    label: 'Reps',
    type: 'text',
    minWidth: 80
  },
  {
    prop: 'time',
    label: 'Time',
    type: 'text',
    minWidth: 80
  },
  {
    prop: 'kg',
    label: 'Kg',
    type: 'text',
    minWidth: 80
  },
  {
    prop: 'link',
    label: 'Link',
    type: 'link',
    minWidth: 300,
    external: true
  },
  {
    prop: 'is_deleted',
    label: 'Hiển thị',
    type: 'badge',
    minWidth: 120
  }
]
