<template>
  <div class="row">
    <div class="col-md-12 card p-0">
      <div class="filter-group-bill">
        <div class="row">
          <div class="col-md-3 filter-col">
            <el-input placeholder="Mã người dùng" v-model="user_id">
            </el-input>
          </div>
          <div class="col-md-3 filter-col">
            <el-input placeholder="Ngày (yyyy-mm-dd)" v-model="date">
            </el-input>
          </div>
          <div class="col-md-3 filter-col">
            <p-button class="full-width btn-load-data" @click="loadData">
              <i class="fa fa-plus"></i>
              Load dữ liệu
            </p-button>
          </div>
        </div>
      </div>
      <div class="card-body row p-0">
        <div class="col-sm-12">
          <my-table :columnDefs="columnDefs" v-bind:data-rows="workouts" :actions="actions"
            :actionsTable="actionsTable" />
        </div>
      </div>
    </div>
    <el-dialog title="Xóa hoạt động" :visible.sync="dialogDeleteVisible" width="30%">
      <div><el-radio v-model="delete_item.type" label="update">Cập nhật trạng thái "Hoạt động đã bị xóa"</el-radio></div>
      <div><el-radio v-model="delete_item.type" label="delete">Xóa ra khỏi database</el-radio></div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogDeleteVisible = false">Hủy</el-button>
        <el-button type="danger" @click="deleteActivity">Xóa</el-button>
      </span>
    </el-dialog>
    <loading :active.sync="isLoading"></loading>
  </div>
</template>

<script>
  import { Message, MessageBox, Dialog, Button, Input, Radio } from 'element-ui';
  import MyTable from 'src/components/UIComponents/Table.vue';
  import workoutSchemas from './workouts-schemas';
  import dtHelper from 'src/helpers/datatable';
  import MySelect from 'src/components/UIComponents/Select';
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';

  export default {
    components: {
      MyTable,
      Message,
      MySelect,
      ElDialog: Dialog,
      ElButton: Button,
      ElInput: Input,
      ElRadio: Radio,
      Loading
    },

    data() {
      return {
        columnDefs: dtHelper.buildColumDefs(workoutSchemas),
        isLoading: false,
        user_id: '',
        date: '',
        delete_item: {
          id: null,
          type: 'update'
        },
        dialogDeleteVisible: false,
        actionsTable: [],
        activities: [],
        actions: [
          {
            type: 'danger',
            icon: 'nc-icon nc-simple-remove',
            callback: this.showModalDelete
          }
        ]
      }
    },

    computed: {
      workouts() {
        let data = this.$store.state.workouts;
        return data;
      }
    },

    methods: {
      showModalDelete(index, row) {
        this.delete_item.id = row.id
        this.dialogDeleteVisible = true;
      },

      deleteActivity() {
        let data = {
          id: this.delete_item.id,
          type: this.delete_item.type
        };
        var index = this.workouts.findIndex(x => x.id == data.id);
        this.isLoading = true;
        this.$store.dispatch('deleteWorkout', data).then(res => {
          if (res.success) {
            if (data.type == 'delete') {
              this.workouts.splice(index, 1);
            } else {
              this.workouts[index].is_deleted = 1;
            }
            Message({
              type: 'success',
              message: 'Hoạt động đã bị xóa'
            });
          } else {
            Message({
              type: 'error',
              message: res.message
            });
          }
          this.dialogDeleteVisible = false;
          this.isLoading = false;
        });
      },
      loadData() {
        if (!this.user_id) {
          return Message({ type: 'error', message: 'Chưa nhập User ID' });
        }
        let data = {
          user_id: this.user_id,
          date: this.date
        };
        this.isLoading = true;
        this.$store.dispatch('fetchAllWorkouts', data);
      }
    },

    mounted() {
      window.AP = this;
      this.$store.dispatch('setPageTitle', 'Workouts');
      this.$store.dispatch('setCurrentFilterUsers', []);
    },

    watch: {
      workouts(newVal) {
        this.isLoading = false;
      }
    },

    destroyed() {
      this.$store.dispatch('setCurrentActions', []);
    }
  }

</script>
<style lang="scss" scope>
  .el-table .td-actions {
    button.btn {
      margin-right: 5px;
    }
  }

  .filter-group-bill {
    padding: 15px;
  }

  .btn-load-data {
    margin: 0;
  }

  .el-table th>.cell {
    font-size: 13px;
  }

</style>
